function header_fixed(){
  if($(window).scrollTop() >= $('.js-hd_fx_trg').offset().top + $('.js-hd_fx_trg').height()){
    headerTag.addClass('-fx');
  }else{
    headerTag.removeClass('-fx');
  }
}

function set_megamenu_position(){
  $('.l-megamenu').css('top',headerTag.outerHeight());
}

function slideout_menu(){
  var slideout = new Slideout({
    'panel': document.getElementById('js-so-panel'),
    'menu': document.getElementById('js-so-menu'),
    'duration': 10,
  });
  var nodeList = document.querySelectorAll('#js-so-toggle, #js-so-panel');
  var node = Array.prototype.slice.call(nodeList,0);
  node.forEach(function(elem, index){
    elem.addEventListener('click', function() {
      slideout.toggle();
      show_language_content(false);
    });
  });
}
function show_search_content(visibility = true){
  if(visibility == true){
    $('.js-search_trg').addClass('is-act');
    $('.js-search_content').fadeIn(600).addClass('is-act');
    $('.js-search_overlay').fadeIn(600).addClass('is-act');
  }else{
    $('.js-search_trg').removeClass('is-act');
    $('.js-search_content').fadeOut(600).removeClass('is-act');
    $('.js-search_overlay').fadeOut(600).removeClass('is-act');
  }
}

function show_language_content(visibility = true){
  if(visibility == true){
    $('.js-lang_trg').addClass('is-act');
    $('.js-lang_content').fadeIn(600).addClass('is-act');
    $('.js-lang_overlay').fadeIn(600).addClass('is-act');
  }else{
    $('.js-lang_trg').removeClass('is-act');
    $('.js-lang_content').fadeOut(600).removeClass('is-act');
    $('.js-lang_overlay').fadeOut(600).removeClass('is-act');
  }
}
function show_tab_trigger(visibility = true){
  $('.js-tab_wrap').each(function(){
    if(visibility == true){
      $(this).find('.js-tab_selected').addClass('is-act');
      $(this).find('.js-tab_trg').show();
    }else{
      $(this).find('.js-tab_selected').removeClass('is-act');
      $(this).find('.js-tab_trg').hide();
    }
  });
}


$(function() {
/*********************************************************************
  common
*********************************************************************/
  if(!$('body').hasClass('-under')){
    header_fixed();
  }
  set_megamenu_position();
  slideout_menu();


  $('.js-mega_trg').parent().hover(
    function(){
      set_megamenu_position();
      show_search_content(false);
      if($('body').hasClass('pc')){
        $(this).children('.js-mega_trg').addClass('is-act');
        $(this).children('.js-mega_content').stop().slideDown(600);
      }
    },
    function(){
      if($('body').hasClass('pc')){
        $(this).children('.js-mega_trg').removeClass('is-act');
        $(this).children('.js-mega_content').stop().slideUp(600);
      }
    }
  );


  $('.js-mega_trg').on('click',function(){
    if($(this).hasClass('is-act')){
      $(this).removeClass('is-act');
      $(this).siblings('.js-mega_content').stop().slideUp(600);
    }else{
      set_megamenu_position();
      show_search_content(false);
      $(this).addClass('is-act');
      $(this).siblings('.js-mega_content').stop().slideDown(600);
    }
  });


  $('.js-lang_trg').on('click',function(){
    if($(this).hasClass('is-act')){
      show_language_content(false);
    }else{
      show_language_content(true);
    }
  });
  $('.js-lang_overlay').on('click',function(){
    if($(this).hasClass('is-act')){
      show_language_content(false);
    }else{
      show_language_content(true);
    }
  });
  $('.js-lang_trg').parent().on('mouseleave',function(){
    if($(this).children('.js-lang_trg').hasClass('is-act')){
      show_language_content(false);
    }
  });
  $('.js-search_trg').on('click',function(){
    if($(this).hasClass('is-act')){
      show_search_content(false);
    }else{
      show_search_content(true);
    }
  });
  $('.js-search_overlay').on('click',function(){
    if($(this).hasClass('is-act')){
      show_search_content(false);
    }else{
      show_search_content(true);
    }
  });

  $('.js-aco_trg').on('click',function(){
    $(this).toggleClass('is-act').next('.js-aco_content').toggleClass('is-act').slideToggle();
  });

  $('.js-rwd_sheet02').each(function(){
    var col_ttl=[];
    $(this).find('thead').find('th').each(function(i){
      col_ttl[i]=$(this).text();
    });
    $(this).find('tbody').find('tr').each(function(){
      $(this).children('th,td').each(function(j){
        $(this).attr('data-ttl',col_ttl[j].trim());
      });
    });
  });

/*********************************************************************
  index
*********************************************************************/

  $('.js-key_slide_pc').vegas({
    slides: [
      { src: './assets/img/index/idx_key_img01.jpg' },
      { src: './assets/img/index/idx_key_img02_pc.jpg' },
      { src: './assets/img/index/idx_key_img03.jpg' },
    ],
    animation: 'kenburns',
    firstTransition: 'none',
    transitionDuration: 2500,
    delay: 6000,
    timer: false,
    align: 'center',
    valign: 'center',
    init: function(globalSettings){
     $('.js-key_text').children('div').eq(globalSettings.slide).addClass('is-act');
    },
    walk: function (index, slideSettings) {
      $('.js-key_text').children('div').removeClass('is-act');
      $('.js-key_text').children('div').eq(index).addClass('is-act');
    },
  });


  $('.js-key_slide_sp').vegas({
    slides: [
      { src: './assets/img/index/idx_key_img01.jpg' },
      { src: './assets/img/index/idx_key_img02_sp.jpg' },
      { src: './assets/img/index/idx_key_img03.jpg' },
    ],
    animation: 'kenburns',
    firstTransition: 'none',
    transitionDuration: 2500,
    delay: 6000,
    timer: false,
    align: 'center',
    valign: 'center',
    init: function(globalSettings){
     $('.js-key_text').children('div').eq(globalSettings.slide).addClass('is-act');
    },
    walk: function (index, slideSettings) {
      $('.js-key_text').children('div').removeClass('is-act');
      $('.js-key_text').children('div').eq(index).addClass('is-act');
    },
  });

  $('.js-tab_wrap').each(function(){
    $(this).find('.js-tab_selected').text($(this).find('.js-tab_trg').find('.is-act').text());
    $(this).find('.js-tab_content').children('.is-act').show().siblings().hide();
    $(this).find('.js-tab_trg').children().on('click',function(){
      idx=$(this).parent('.js-tab_trg').children('li').index(this);
      $(this).addClass('is-act').siblings().removeClass('is-act');
      $(this).parent('.js-tab_trg').siblings('.js-tab_trg').children().eq(idx).addClass('is-act').siblings().removeClass('is-act');
      $(this).parent('.js-tab_trg').siblings('.js-tab_content').children().removeClass('is-act').hide().eq(idx).fadeIn().addClass('is-act');
      $(this).parent('.js-tab_trg').siblings('.js-tab_selected').text($(this).text());
    });
  });
  $('.js-tab_selected').on('click',function(){
    $(this).toggleClass('is-act').next('.js-tab_trg').slideToggle();
  });

  $('.js-hash_tab').each(function(){
    var hash=location.hash;
    $('.js-hash_tab_content').children().hide();
    if(hash){
      console.log('load:'+hash);
      $(this).find('.js-hash_tab_trg').find('a[href="'+hash+'"]').addClass('is-act');
      $(this).find('.js-hash_tab_content').find(hash).show();
    }else{
      $(this).find('.js-hash_tab_trg').children('li').eq(0).children('a').addClass('is-act');
      $(this).find('.js-hash_tab_content').children().eq(0).show();
    }
    $(this).find('.js-hash_tab_trg a').on('click',function(event){
      event.preventDefault();
      hash=$(this).attr('href');
      console.log('click:'+hash);
      $(this).parents('.js-hash_tab_trg').find('a').removeClass('is-act');
      $(this).parents('.js-hash_tab_trg').siblings('.js-hash_tab_trg').find('a').removeClass('is-act');
      $(this).parents('.js-hash_tab_trg').siblings('.js-hash_tab_content').children().hide();
      $(this).parents('.js-hash_tab_trg').find('a[href="'+hash+'"]').addClass('is-act');
      $(this).parents('.js-hash_tab_trg').siblings('.js-hash_tab_trg').find('a[href="'+hash+'"]').addClass('is-act');
      $(this).parents('.js-hash_tab_trg').siblings('.js-hash_tab_content').children(hash).fadeIn();
      history.replaceState('','',location.pathname+location.search+hash);
      var href= $(this).attr("href");
      var target = $(href == "#" || href == "" ? 'html' : href);
      var headHeight = headerTag.outerHeight();
      var position = target.offset().top - headHeight;
      if ( href == '#' ) { position = 0; }
      $('body,html').animate({scrollTop:position}, 600, 'swing');
    });
  });
/*********************************************************************
  search
*********************************************************************/
  $('.js-editor table').each(function(){
    $(this).wrap('<div class="c-rwd_sheet"><div class="inner"></div></div>');
  });
  $('.js-editor iframe').each(function(){
    $(this).wrap('<div class="c-iframe"></div>');
  });
  $('.js-alert').on('click',function(){
    var href=$(this).attr('href');
      var res=confirm("ファイルのダウンロードは会員のみが可能です。");
      if(res==true){
        var speed = 600;
        var href= $(this).attr("href");
        var target = $(href == "#" || href == "" ? 'html' : href);
        var headHeight = headerTag.outerHeight();
        var position = target.offset().top - headHeight;
        if ( href == '#' ) { position = 0; }
        $('body,html').animate({scrollTop:position}, speed, 'swing');
        return false;
      }else{
        return false;
      }
  });

  $('.js-search-slick>ul').slick({
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplaySpeed: 5000,
    speed: 600,
    dots:false,
    arrows:true,
    prevArrow: '<span class="prev arrow"></span>',
    nextArrow: '<span class="next arrow"></span>',
    autoplay: true,
    fade: false,
    //レスポンシブでの動作を指定
    responsive:[
      {
        breakpoint: 992,
        settings:{
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 768,
        settings:{
          autoplay:false,
          swipeToSlide: true,
          slidesToShow: 3,
          slidesToScroll: 1,
          vertical:true,
          swipeToSlide:true,
        }
      },
    ]
  });

/*********************************************************************
  support
*********************************************************************/
  $('.js-switch_row').on('change',function(){
    var val=$(this).val();
    var target_row=$(this).children('option[value="'+val+'"]').attr('data-target-row');
    $(this).parents('.js-mailform').find('[data-row]').each(function(){
      if($(this).attr('data-row') == target_row){
        $(this).show();
      }else{
        $(this).hide();
      }
    });
  });
});




$(window).on('scroll',function(){
  if(!$('body').hasClass('-under')){
    header_fixed();
  }
  show_language_content(false);
  show_search_content(false);
  $('.js-mailform').each(function(){
    var val=$(this).find('.js-switch_row').val();
    var target_row=$(this).find('.js-switch_row').children('option[value="'+val+'"]').attr('data-target-row');

    $(this).find('[data-row]').each(function(){
      console.log(target_row);
      if($(this).attr('data-row') == target_row){
        $(this).show();
      }else{
        $(this).hide();
      }
    });
  });
});

$(window).on('resize',function(){
  set_megamenu_position();
  show_language_content(false);
  show_search_content(false);
  show_tab_trigger(false);
});
